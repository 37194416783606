// ================================ COLORS =============================================

.Pro_orange {
  background-color: #de5b46;
  color: #de5b46;
}

.Pro_green {
  background-color: #029499;
  color: #029499;
}

.Pro_yellow {
  background-color: #f69a3e;
  color: #f69a3e;
}

.ProText_orange {
  color: #de5b46 !important;
}

.ProText_green {
  color: #029499;
}

.ProText_yellow {
  color: #f69a3e !important;
}

.ProText_blue {
  color: #294177;
}

.btnBorder {
  border: #f69a3e !important;
  color: #f69a3e !important;
}

.textColor {
  color: #fd7e14;
}

.btn-green {
  background-color: #029499 !important;
}

.linkHighlight:hover {
  color: #029499 !important;
  cursor: pointer;
}

.btn-outline-warning {
  --bs-btn-color: #f69a3e;
  --bs-btn-border-color: #f69a3e;
  --bs-btn-hover-color: #029499;
  --bs-btn-hover-bg: #f69a3e;
  --bs-btn-hover-border-color: #f69a3e;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #029499;
  --bs-btn-active-bg: #f69a3e;
  --bs-btn-active-border-color: #f69a3e;
  --bs-btn-active-shadow: inset 0 3px 5px #029499;
  --bs-btn-disabled-color: #f69a3e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f69a3e;
  --bs-gradient: none;
}

//=========================================================================================================

// =====================================================GLOBAL CSS============================================

.allMaringTop {
  padding-top: 5rem;
}

.marginTopHddings {
  margin-top: 6rem;
}

.cardRadi {
  border-radius: 40px;
}

.cardBottomRadi {
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}

.cardBottomRadi-2 {
  border-top-left-radius: 0%;
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  border-bottom-left-radius: 0%;
}

.cardBottomRadi-3 {
  border-top-left-radius: 10rem;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 10rem;
}

.CardFixedHight {
  height: 100%;
}

.CardFixedHight1 {
  height: 20rem;
}

.CardFixedHight2 {
  height: 80%;
}

.overflowHidden {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

.dropdown-menu-width {
  width: 12rem !important;
}

p.addresss {
  color: #000;
  font-size: 14px;
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
}

.lactive-class {
  color: #ff0000 !important;
}

//=========================================================================================================

//==============================================CARD BACKGROUN IMAGE=======================================
.cardBgImage {
  background-image: url("./Media/Images/ProdEnggCover.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.gptwTostmasterGb {
  background-image: url("./Media/Images/tostmaster.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.guidewireCard1 {
  background-image: url("./Media/Images/case-study-banner-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.guidewireCard2 {
  background-image: url("./Media/Images/quality\ testing.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.guidewireCard3 {
  background-image: url("./Media/Images/case-study-banner-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.guidewireCard4 {
  background-image: url("./Media/Images/top-agency-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

// ========================================================================================================

// ================================ CAROUSEL =============================================
.carousel-indicators [data-bs-target] {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}

.carousel-cus-indicators [data-bs-target] {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  background-color: rgb(0, 0, 0);
}

.carousel-Testimonial-indicators [data-bs-target] {
  background-color: rgb(0, 0, 0);
}

.activeDots .active {
  background-color: rgb(0, 0, 0) !important;
}

.activeDots2 .active {
  background-color: rgb(255, 255, 255) !important;
}

.carousel-cus-indicators {
  position: absolute;
  right: 0;
  bottom: 30%;
  left: 70% !important;
  display: inline !important;
  z-index: 2;
  justify-content: center !important;
  padding: 0;
  margin-right: 20%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-control-next-btn {
  bottom: 100% !important;
}

// =============================================================================

//=================================================ANIMATION CARD============================================
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  // width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: warp;
  // padding: 30px;
}

.container .cards {
  position: relative;
  max-width: 300px;
  height: 215px;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;

  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px #9f9d9d;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}

.container .cards:hover {
  height: 400px;
}

.container .cards .image {
  position: relative;
  width: 260px;
  height: 260px;

  top: -20%;
  left: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.container .cards .image img {
  max-width: 100%;
  border-radius: 15px;
}

.container .cards .content {
  position: relative;
  top: -80px;
  padding: 10px 15px;
  color: #111;

  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.container .cards:hover .content {
  margin-top: 30px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}

.imageCardRecrutor {
  border-radius: 15px;
}

@media (min-width: 764px) {
  .btn-marginTop {
    padding-top: 80% !important;
  }
}

@media (max-width: 765px) {
  .btn-marginTop {
    padding-top: 2% !important;
  }

  .displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.carousel-control-prev-icon1,
.carousel-control-next-icon1 {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;

  background-image: none;
}

.carousel-control-next-icon1:after {
  content: ">";
  font-size: 55px;
  color: red;
}

.carousel-control-prev-icon1:after {
  content: "<";
  font-size: 55px;
  color: red;
}

.card-Style {
  outline: none;
  border: 0.5px;
  border-color: #ffffff;
  box-shadow: 0 0 10px #00000029;
}

.card1 {
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 15px 10px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border-radius: 10%;
}

.card2 {
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  // border-radius: 10%;
}

.card2:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #030329;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.8s ease-out;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #030329;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.8s ease-out;
}

.card2:hover:before {
  transform: scale(70);
}

.card1:hover:before {
  transform: scale(70);
}

.card2:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card2:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.cardBgColor {
  background-color: #030329 !important;
}

.line {
  border-bottom: 1px solid black;
}

//==================================================================================================

// ======================marquee second=========================================>

.marquee-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.marquee-box {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
  // background-color: white;
  border-radius: 50px;
}

.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: marqueescroll 100s linear 0s infinite;
  animation-play-state: running;
  animation-delay: 0s;
  animation-direction: normal;
}

.marquee figure {
  margin-right: 4rem;
  margin-left: 4rem;
  padding: 5px;
  max-width: 250px;
  text-align: center;
  vertical-align: top;
}

.marquee figcaption {
  display: none;
  font-weight: 80%;
  color: #333;
  line-height: 1.2;
}

.marquee img {
  margin: 0 auto;
  height: auto;
  max-height: 3.5rem;
  max-width: 100%;
  border: none;
}

.marquee-box:hover div {
  animation-play-state: running;
  background-color: white;
  transition: 0.4s ease-in;
}

.marquee-box:active div {
  animation-play-state: running;
}

@keyframes marqueescroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

// .text-decoration{
//   text-decoration: none !important;
// }
NavLink,
li,
a {
  text-decoration: none !important;
}

//==================================================================================================

// =================================Footer=======================================>

.footerBg {
  background-color: #029499;
}

.footerLink {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.footerLink ::before,
.footerLink ::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}

.footerLink ::before {
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.footerLink ::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #000000;
}

.footerLink :hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.footerLink :hover::after {
  transform: translate3d(0, 0, 0);
}

.footerLink span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.footerLink :hover span {
  transform: translate3d(-200%, 0, 0);
}

a {
  text-decoration: none;
  color: #18272f;
  font-weight: 700;
  vertical-align: top;
}

// ========================================================================>

// =====================================NAV DROPDOWN========================================================

@media all and (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    margin-top: 0;
  }

  .navbar .nav-item:hover .nav-link {
    color: #fff;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }

  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
}

@media (min-width: 764px) {
  .dropdownShadow {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

// ============================================NAV BAR ======================================================
.topFixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background: #ffffff;
}

.navBg {
  background-color: #ffffff;
  transition: 0.4s ease-in;
  box-shadow: 2px 2px 10px #029499;
}

// ==================================================================================================

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #02949950;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #de5b46;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  // border: 3px solid orange;
}

.inputBorder {
  border-radius: 0% !important;
  border-bottom: 1px solid grey !important;
}

.gptwCoverBg {
  background-image: url("./Media/Images/gptwCover.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

* {
  box-sizing: border-box;
}

.cardStyle {
  height: 360px;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.cardStyle:hover {
  transform: translateY(20px);
}

.cardStyle:hover:before {
  opacity: 1;
}

.cardStyle:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.cardStyle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.cardStyle img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.cardStyle .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}

.cardStyle .info h1 {
  margin: 0px;
}

.cardStyle .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.cardStyle .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}

.cardStyle .info button:hover {
  background: dodgerblue;
  color: white;
}

.card-hover:hover {
  transform: scale(1.04);
}

.card-hover {
  transition: transform 0.4s;
}

.card-hover-left:hover {
  transform: translate(-16px);
}

.card-hover-left {
  transition: transform 0.4s;
}

.buttonLine::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: #de5b46;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.buttonLine:hover::after {
  transform: scaleX(1);
}

.counter-area {
  background: rgba(0, 0, 0, 0);
  padding: 95px 0 65px;
}

.counter-area::before {
  opacity: 0.8 !important;
}

.counter-area .section-title h2 {
  color: #fff;
}

.all-counter {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
}

.counter-box {
  -webkit-flex: 1 1 25%;
  -moz-flex: 1 1 25%;
  -ms-flex: 1 1 25%;
  -o-flex: 1 1 25%;
  flex: 1 1 25%;
  margin: 0 0 30px;
  text-align: center;
  position: relative;
}

.counter-box::after {
  background: #91908d none repeat scroll 0 0;
  content: "";
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
}

.counter-box:last-child::after {
  display: none;
}

.counter-box .counter {
  color: #3fb698;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  width: auto;
  display: inline-block;
}

.counter-box .text {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 25px;
  margin: 2px 0 0;
}

.bg-with-black {
  position: relative;
}

.bg-with-black::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-with-white {
  position: relative;
}

.bg-with-white::before {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.plus {
  display: inline-block;
  color: #3fb698;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
}

.fade-in-page {
  opacity: 0;
  /* Start with 0 opacity to hide the content initially */
  animation: fadeIn 3s forwards;
  /* Use the "fadeIn" animation for 2 seconds */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* Start from 0 opacity */
  }

  to {
    opacity: 1;
    /* End at 100% opacity */
  }
}

#sidebar-nav {
  width: 160px;
}

ul.nestedlist li {
  list-style-type: none;
}

ul.nestedlist li ul li {
  list-style-type: disc;
}

ul.normal li {
  list-style-type: disc;
}

// =============================SkillTraining==========================

.skill_section {
  background-color: #000;
  height: 520px;
  background-image: url("./Media/Images/traingImage.jpg");
  width: 100%;
  background-size: cover;
  position: relative;
  padding-left: 80px;
  padding-right: 80px
}

.skill_section h1 {
  color: #fff;
}

.black_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
}

.paragraph_style {
  color: lightgray;
  font-size: 18px;

}

.traning_program_section {
  background: white;
  padding: 50px 100px 60px 100px;
}

.goverment_section {
  background: whitesmoke;
  padding: 50px 100px 60px 100px;
}

.training_areas_title {
  font-size: 18px;
}

.goverment_section .nested ul {
  margin-bottom: 15px !important;
}

.goverment_section .nested ul li {
  color: #707070;
  font-size: 15px;
  margin-top: 8px;
}

.private_section {
  background-color: rgba(33, 37, 41, 1) !important;
  padding: 50px 100px 60px 100px;
}

.nested_key_points ul {
  margin-bottom: 15px !important;
}

.nested_key_points ul li {
  color: whitesmoke;
  font-size: 15px;
  margin-top: 8px;
}

.nested_key_points h5 {
  color: white;
  font-size: 18px;
}

.benifit_program_section {
  background: white;
  padding: 50px 100px 60px 100px;
}

.benifit_program_section .nested h5 {
  font-size: 18px;
}

.benifit_program_section .nested ul {
  margin-bottom: 15px !important;
}

.benifit_program_section .nested ul li {
  font-size: 15px;
  margin-top: 8px;
}

.enroll_section {
  background: whitesmoke;
  padding: 50px 100px 60px 100px;
}

.enroll_section h5 {
  font-size: 18px;
}

.enroll_section .normal li {
  font-size: 15px;
  margin-top: 8px;
}

.why_choose_section {
  background: white;
  padding: 50px 100px 30px 100px;
}

.why_choose_section .nested h5 {
  font-size: 18px;
}

.why_choose_section .nested li {
  font-size: 15px;
  margin-top: 8px;
}

.skill_section h1 {
  line-height: 50px;
}